import { graphql } from 'msw'
import { auth, step1, step2, step3, step4, step5 } from './handlers'

const mocks = [
	...step1,
	...step2,
	...step3,
	...step4,
	...step5,
	...auth,
	graphql.query('instanceStatus', (req, res, ctx) =>
		res(
			ctx.data({
				instanceStatus: {
					statusProgress: 90,
					status: 'SUCCESS'
				}
			})
		)
	),
	graphql.mutation('updateStep', (req, res, ctx) =>
		res(
			ctx.data({
				updateStep: {
					ok: true,
					errors: []
				}
			})
		)
	)
]

// eslint-disable-next-line import/prefer-default-export
export { mocks }
