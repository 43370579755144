export interface IFormInputs {
	email: string
	firstName: string
	lastName: string
	password1: string
	password2: string
	schoolPolicy: boolean
	termsOfUse: boolean
}
type Name = keyof IFormInputs

interface Fields {
	label: string
	name: Name
	placeholder: string
	type?: string
	className: string
	isPassword?: boolean
}

export const fields: Array<Fields> = [
	{
		label: 'First name',
		name: 'firstName',
		placeholder: 'Type first name',
		className: 'field__mb24'
	},
	{
		label: 'Last name',
		name: 'lastName',
		placeholder: 'Type last name',
		className: 'field__mb24'
	},
	{
		label: 'Email',
		name: 'email',
		type: 'email',
		placeholder: 'Type email',
		className: 'field__mb24'
	},
	{
		label: 'Password',
		name: 'password1',
		placeholder: 'Create password',
		isPassword: true,
		className: 'field__mb24'
	},
	{
		label: 'Confirm password',
		name: 'password2',
		placeholder: 'Type password again',
		isPassword: true,
		className: 'field__mb24'
	}
]
