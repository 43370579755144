import Button from 'components/elements/Button'
import FieldInput from 'components/elements/FieldInput'
import Copyright from 'components/elements/Copyright'
import { useLazyQuery, useMutation } from '@apollo/client'
import { LOGIN } from 'graphql/mutation'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatchAuth } from 'context/auth'
import { GET_USER } from 'graphql/query'
import styles from './SecurityPage.module.scss'

const SecurityPage = () => {
	const [code, setCode] = useState<string>('')
	const { dispatch } = useDispatchAuth()
	const navigate = useNavigate()
	const [error, setError] = useState<string>('')

	const [getUser] = useLazyQuery(GET_USER, {
		onCompleted({ registration }) {
			if (registration.domain) {
				dispatch({ type: 'LOGIN', payload: { ...registration, isAuthenticated: true } })
			}
		}
	})

	const [loginCode, { loading }] = useMutation(LOGIN, {
		onCompleted: async ({ login }) => {
			if (login.ok) {
				await getUser()
				localStorage.removeItem('countDownDate')
				localStorage.removeItem('countDownExtraDate')
				dispatch({ type: 'SET_DOMAIN', payload: 'domain' })
				setError('')
				navigate(`/step${login.step || '1'}`)
				return
			}
			if (!login.ok) {
				setError(login.errors.map((item: { errors: string[] }) => item.errors).join(', '))
			}
		}
	})

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCode(e.target.value)
	}
	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		if (code) {
			loginCode({
				variables: {
					code: code.trim()
				}
			})
		}
	}
	return (
		<div className={styles.wrapper}>
			<img src="/images/logotype-big.png" alt="" className={styles.logo} />
			<h1 className={styles.title}>Security control</h1>
			<p className={styles.subtitle}>
				We care about protecting our clients' data. Call us for a security code to proceed
				with your registration
			</p>
			<div className={styles.security}>
				<div className={styles.security__node}>
					<i className="icon-phone-solid" />{' '}
					<a href="tel:0333 242 3163" className={styles.security__number}>
						0333 242 3163
					</a>
				</div>
				<p className={styles.security__subtitle}>We are open from 8.30am to 6pm daily</p>
			</div>
			<form onSubmit={onSubmit}>
				<FieldInput
					label="Security code"
					name="code"
					placeholder="Type security code"
					onChange={handleChange}
					value={code}
					required
					error={error}
				/>
				<Button block type="submit" isLoading={loading}>
					Proceed to register
				</Button>
			</form>
			<Copyright />
		</div>
	)
}

export default SecurityPage
