import Button from 'components/elements/Button'
import Select from 'components/elements/CustomSelect/CustomSelect'
import Header from 'components/modules/Header'
import { useStateAuth } from 'context/auth'
import { useMutation, useQuery } from '@apollo/client'
import { GET_CHILDREN_QUANTITY } from 'graphql/query'
import React, { useState } from 'react'
import { CREATE_CHILDREN_QUANTITY, UPDATE_STEP } from 'graphql/mutation'
import { useNavigate } from 'react-router-dom'
import Badge from 'components/elements/Badge'
import WithAuth from 'hoc/withAuth'
import { serializeErrors } from 'utils'
import css from './Step5.module.scss'

const Step5 = () => {
	const [quantity, setQuantity] = useState<number>(0)
	const [error, setError] = useState<{ [key: string]: { message: string[] } }>({})
	const [show, setShow] = useState<boolean>(false)
	const navigate = useNavigate()
	const { data, loading } = useQuery(GET_CHILDREN_QUANTITY)
	const [updateStep] = useMutation(UPDATE_STEP)
	const [sendQuantity, { loading: apiLoading }] = useMutation(CREATE_CHILDREN_QUANTITY, {
		onCompleted({ createChildrenQty }) {
			if (createChildrenQty.ok) {
				navigate('/step6')
				updateStep({
					variables: {
						step: 6
					}
				})
			} else {
				setError(serializeErrors(createChildrenQty.errors))
				setShow(true)
			}
		}
	})
	const { state } = useStateAuth()

	const onChange = (value: { label: string; value: number }) => {
		setQuantity(value.value)
		setError((prev) => ({ ...prev, quantity: { message: [''] } }))
	}

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		if (!quantity) {
			setError((prev) => ({ ...prev, quantity: { message: ['Field is required'] } }))
			return
		}
		sendQuantity({
			variables: {
				quantity
			}
		})
	}

	const onCancel = () => {
		navigate('/step4')
	}

	return (
		<div className="step">
			<Header step={5} />
			<form className="step__form" onSubmit={onSubmit}>
				{show && (
					<Badge
						color="error"
						text={error?.nonFieldErrors?.message}
						show={show}
						toggleShow={() => setShow(false)}
					/>
				)}
				<div className="container centered-container-md">
					<div className="step__titles">
						<h2 className="step__title">How many children are protected?</h2>
						<p className="step__subtitle">
							The website activation process will start once you have selected the
							approximate number of children who are protected by our licences
						</p>
					</div>
					<div className="container container-md2">
						<div className={css.selectWrapper}>
							<label htmlFor="quantity" className={css.label}>
								Children
							</label>
							<p className={css.sublabel}>
								Please select approximate number of children protected by our
								licences
							</p>
							<Select
								onChange={onChange}
								name="quantity"
								error={error?.quantity?.message?.join('. ')}
								isLoading={loading}
								defaultValue={{
									label: 'Select the number of children',
									value: 'default'
								}}
								list={data?.childrenQtyChoices}
							/>
						</div>
						<div className={css.data}>
							<img
								src={state.school?.country?.icon}
								alt={state.school?.country?.name}
								className={css.data__flag}
							/>
							<div className={css.data__info}>
								<p className={css.data__title}>
									The data will be stored in:{' '}
									<span className={css.data__country}>
										{state.school?.country?.name}
									</span>
								</p>
								<p className={css.data__subtitle}>
									We care about the security of our customers' data and make sure
									that everything is protected
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="step-buttons">
					<div className="step-buttons__wrapper">
						<Button
							variant="empty"
							classname="step-buttons__backBtn"
							onClick={onCancel}
						>
							<i className="icon-arrow-left" />
							Back
						</Button>
						<Button
							type="submit"
							variant="primary"
							classname="step-buttons__next"
							isLoading={apiLoading}
						>
							Get started
						</Button>
					</div>
				</div>
			</form>
		</div>
	)
}

export default WithAuth(Step5)
