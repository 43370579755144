import { useFormContext, useWatch } from 'react-hook-form'
import styles from './ProtectionBlock.module.scss'
import protectionData from './data'

const ProtectionBlock = () => {
	const { control } = useFormContext()
	const title = useWatch({
		control,
		name: 'titleSection3'
	})

	return (
		<div className={styles.block}>
			<div className="container-preview">
				<h2 className={styles.block__title}>{title}</h2>
				<div className={styles.block__items}>
					{protectionData.map((item, index) => (
						<div className={styles.block__item} key={item.id}>
							<span className={styles.block__number}>{`0${index + 1}`}</span>
							<div>
								<img src={item.image} alt="" className={styles.block__image} />
								<p className={styles.block__p}>{item.text}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default ProtectionBlock
