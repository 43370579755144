import React from 'react'
import {
	Footer,
	HeroBlock,
	PolicyBlock,
	ProtectionBlock,
	Header,
	GetStarted,
	PreviewHeader
} from 'components/modules/Preview'
import css from './Preview.module.scss'

type PreviewProps = {
	logo: Blob | null
}
const Preview: React.FC<PreviewProps> = ({ logo }) => (
	<div className={css.preview}>
		<PreviewHeader />
		<div>
			<Header />
			<HeroBlock logo={logo} />
			<GetStarted />
			<ProtectionBlock />
			<PolicyBlock />
			<Footer />
		</div>
	</div>
)

export default Preview
