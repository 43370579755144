import { graphql } from 'msw'
import correct from './fixtures/correct.json'
import incorrect from './fixtures/incorrect.json'
import user from './fixtures/getUser.json'

const auth = [
	graphql.mutation('login', (req, res, ctx) => {
		const code = '7f4wZ7qJIc'

		if (req.variables.code !== code) {
			return res(ctx.delay(1000), ctx.data(incorrect))
		}
		return res(ctx.delay(1000), ctx.data(correct))
	}),
	graphql.query('getUser', (req, res, ctx) => res(ctx.data(user)))
]
// eslint-disable-next-line import/prefer-default-export
export { auth }
