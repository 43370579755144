import Button from 'components/elements/Button'
import Checkbox from 'components/elements/Checkbox'
import FieldInput from 'components/elements/FieldInput'
import Header from 'components/modules/Header'
import { useForm, SubmitHandler, Controller, FormProvider } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { CREATE_PROFILE, UPDATE_PROFILE, UPDATE_STEP } from 'graphql/mutation'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Badge from 'components/elements/Badge'
import { useStateAuth, useDispatchAuth } from 'context/auth'
import { serializeErrors } from 'utils'
import WithAuth from 'hoc/withAuth'
import { fields, IFormInputs } from './fields'

const Step1 = () => {
	const methods = useForm<IFormInputs>({
		mode: 'onSubmit',
		defaultValues: {
			email: '',
			firstName: '',
			lastName: '',
			password1: '',
			password2: '',
			schoolPolicy: false,
			termsOfUse: false
		}
	})

	const { control, handleSubmit } = methods

	const [errors, setErrors] = useState<{ [key: string]: { message: string[] } }>({})
	const [show, setShow] = useState<boolean>(false)
	const [updateStep] = useMutation(UPDATE_STEP)
	const navigate = useNavigate()
	const { dispatch } = useDispatchAuth()
	const { state } = useStateAuth()

	useEffect(() => {
		if (state.profile) {
			methods.setValue('email', state.profile.email ?? '')
			methods.setValue('firstName', state.profile.firstName ?? '')
			methods.setValue('lastName', state.profile.lastName ?? '')
		}
	}, [state.profile, methods])

	const [register, { loading }] = useMutation(CREATE_PROFILE, {
		onCompleted({ createProfile }, options) {
			if (!createProfile.ok) {
				setErrors(serializeErrors(createProfile.errors))
				setShow(true)
			} else {
				dispatch({
					type: 'UPDATE_PROFILE',
					payload: {
						firstName: options!.variables!.firstName as string,
						lastName: options!.variables!.lastName as string,
						email: options!.variables!.email as string
					}
				})
				setErrors({})
				updateStep({
					variables: {
						step: 2
					}
				})
				navigate(`/step2`)
				setShow(false)
			}
		}
	})
	const [update, { loading: updateLoading }] = useMutation(UPDATE_PROFILE, {
		onCompleted({ updateProfile }, options) {
			if (!updateProfile.ok) {
				setErrors(serializeErrors(updateProfile.errors))
				setShow(true)
			} else {
				setErrors({})
				dispatch({
					type: 'UPDATE_PROFILE',
					payload: {
						firstName: options!.variables!.firstName as string,
						lastName: options!.variables!.lastName as string,
						email: options!.variables!.email as string
					}
				})
				navigate(`/step2`)
				setShow(false)
			}
		}
	})

	const onSubmit: SubmitHandler<IFormInputs> = ({ email, ...data }: IFormInputs) => {
		if (state.profile) {
			update({ variables: { email: email.trim(), ...data } })
			return
		}
		register({ variables: { email: email.trim(), ...data } })
	}

	const onCancel = () => {
		navigate(`/`)
	}

	const loader = loading || updateLoading

	return (
		<div className="step">
			<Header step={1} />
			<FormProvider {...methods}>
				<form
					className="step__form container-lg"
					data-testid="form"
					onSubmit={handleSubmit(onSubmit)}
				>
					{show && (
						<Badge
							color="error"
							text={errors?.nonFieldErrors?.message}
							show={show}
							toggleShow={() => setShow(false)}
						/>
					)}
					<div className="container container-md  ">
						<div className="step__titles">
							<h2 className="step__title">Create profile</h2>
						</div>
						<div>
							{fields.map((item) => (
								<Controller
									key={item.name}
									name={item.name}
									control={control}
									rules={{ required: true }}
									render={({ field: { onChange, onBlur, value } }) => (
										<FieldInput
											{...item}
											value={value?.toString()}
											required
											name={item.name}
											onBlur={onBlur}
											onChange={onChange}
											error={errors[item.name]?.message?.join('. ')}
										/>
									)}
								/>
							))}
							<div className="step__field">
								<div className="step__checkbox">
									<Checkbox
										id="termsOfUse"
										name="termsOfUse"
										label={
											<span className="step__checkboxText">
												I agree to
												<a href="https://schoolsmobile.com/en-us/terms-conditions-usage/">
													Terms & Conditions
												</a>
											</span>
										}
										error={errors?.termsOfUse?.message?.join('. ')}
										register={methods.register}
									/>
								</div>
							</div>
							<div>
								<div className="step__checkbox">
									<Checkbox
										id="schoolPolicy"
										label={
											<span className="step__checkboxText">
												I agree to
												<a href="https://schoolsmobile.com/en-us/privacy-policy-statement/">
													School ISafetyPolicy
												</a>
											</span>
										}
										name="schoolPolicy"
										error={errors?.schoolPolicy?.message?.join('. ')}
										register={methods.register}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="step-buttons">
						<div className="step-buttons__wrapper">
							<Button variant="empty" onClick={onCancel}>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="primary"
								isLoading={loader}
								classname="step-buttons__next"
							>
								Continue
							</Button>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default WithAuth(Step1)
