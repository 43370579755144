import { useStateAuth } from 'context/auth'
import React, { useEffect, useState } from 'react'

const regEmail =
	/^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,4}$/gm

function useEmails() {
	const [value, setValue] = useState('')
	const [items, setItems] = useState<string[]>([])
	const [error, setError] = useState<string | null>(null)

	const { state } = useStateAuth()

	useEffect(() => {
		if (state.emails.length) {
			setItems(state.emails)
		}
	}, [state.emails])

	function isInList(mail: string) {
		return items.includes(mail)
	}

	function isEmail(): boolean {
		return regEmail.test(value)
	}

	function isValid(): boolean {
		if (isInList(value)) {
			setError(`${value} has already been added.`)
			return false
		}

		if (isEmail()) {
			setError(null)
			return true
		}

		if (!isEmail()) {
			setError(`${value} is not a valid email address.`)
			return false
		}

		setError(null)
		return true
	}

	const handleKeyDown = (evt: React.KeyboardEvent<HTMLElement>) => {
		if (['Enter', ','].includes(evt.key)) {
			evt.preventDefault()

			if (value.trim() && isValid()) {
				setValue('')
				setItems((prev) => [...prev, value.trim()])
			}
		}
	}

	const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setError(null)
		setValue(evt.target.value)
	}

	const handleDelete = (item: string) => {
		setError(null)
		setItems(items.filter((i) => i !== item))
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handlePaste = (evt: any) => {
		evt.preventDefault()

		let paste = evt.clipboardData.getData('text')
		let emailsArray = paste.split(',').map((item: string) => item.trim())
		let allCorrect = emailsArray.every((item: string) => item.match(regEmail))

		if (allCorrect) {
			let uniqueArray = emailsArray.filter(
				(val: string, index: number, array: string[]) => array.indexOf(val) === index
			)
			let toBeAdded = uniqueArray.filter((email: string) => !isInList(email))
			setError(null)
			setItems((prev) => [...prev, ...toBeAdded])
		} else {
			const correct = emailsArray.filter((item: string) => item.match(regEmail))
			const incorrect = emailsArray.find((item: string) => !item.match(regEmail))
			setError(`${incorrect} is not a valid email address`)
			let toBeAdded = correct.filter((email: string) => !isInList(email))
			setItems((prev) => [...prev, ...toBeAdded])
		}
	}

	const resetEmails = () => {
		setItems([])
		setValue('')
	}

	const handleError = (err: string) => {
		setError(err)
	}

	return {
		value,
		items,
		handleKeyDown,
		handleChange,
		handlePaste,
		handleDelete,
		resetEmails,
		handleError,
		error
	}
}

export default useEmails
