import css from './NotFound.module.scss'

const NotFound = () => (
	<div className={css.wrapper}>
		<img src="/images/logotype-big.png" alt="" className={css.logo} />
		<div className={css.notFound}>
			<p>404 Page Not Found</p>
			<a className={css.link} href="/">
				Login page <i className={`icon-big-arrow-right ${css.link__arrow}`} />
			</a>
		</div>
	</div>
)

export default NotFound
