import classNames from 'classnames'
import React from 'react'
import styles from './Progress.module.scss'

export type ProgressProps = {
	step: 1 | 2 | 3 | 4 | 5
	className?: string
}

const Progress: React.FC<ProgressProps> = ({ step, className }) => {
	const steps = 100 / 5
	const progressClass = classNames(styles.progress, className)
	return (
		<div className={progressClass} data-testid="progress">
			Step •{' '}
			<span className={styles.progress__active} data-testid="progress-step">
				{step}
			</span>{' '}
			/ 5
			<span
				className={styles.progress__line}
				style={{ width: `${step * steps}%` }}
				data-testid="progress-line"
			/>
		</div>
	)
}

export default Progress
