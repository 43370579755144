import { graphql } from 'msw'
import successful from './fixtures/successful.json'
import unsuccessful from './fixtures/unsuccessful.json'

const step4 = [
	graphql.mutation('createEmails', (req, res, ctx) => {
		const { emails } = req.variables
		console.log('graphql.mutation   req.variables:', req.variables)
		if (emails.includes('email@mail.cc')) {
			return res(ctx.data(unsuccessful))
		}
		return res(ctx.data(successful))
	})
]

// eslint-disable-next-line import/prefer-default-export
export { step4 }
