import React from 'react'
import Loader from '../Loader'
import css from './PageLoading.module.scss'

const PageLoading: React.FC = () => (
	<div className={css.loader}>
		<Loader color="grey" width="200px" />
	</div>
)

export default PageLoading
