import { gql } from '@apollo/client'

export const GET_COUNTRIES = gql`
	query countries {
		countries {
			id
			name
			icon
		}
	}
`
export const GET_COUNTRY = gql`
	query country($id: ID) {
		country(id: $id) {
			id
			name
			icon
		}
	}
`

export const GET_CHILDREN_QUANTITY = gql`
	query childrenQtyChoices {
		childrenQtyChoices {
			label
			value
		}
	}
`

export const GET_PROGRESS = gql`
	query instanceStatus {
		instanceStatus {
			statusProgress
			status
		}
	}
`
export const GET_USER = gql`
	query getUser {
		registration {
			isCompleted
			step
			profile {
				firstName
				lastName
				email
			}
			domain
			fullDomain
			school {
				name
				country {
					id
					name
					icon
				}
				city
				address
				postCode
			}
			emails
			siteInfo {
				logo
				titleSection1
				subtitleSection1
				titleSection2
				subtitleSection2
				titleSection3
				titleSection4
				subtitleSection4
			}
		}
	}
`
