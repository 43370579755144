import React from 'react'
import FileUpload from 'components/elements/FileUpload'
import Textarea from 'components/elements/Textarea'
import { Controller, useFormContext } from 'react-hook-form'
import css from './Form.module.scss'

type FormProps = {
	handleFiles: (file: Blob) => void
	file: Blob | null
}
const Form: React.FC<FormProps> = ({ handleFiles, file }) => {
	const {
		control,
		formState: { errors }
	} = useFormContext()

	return (
		<div className={css.form}>
			<div className={css.form__logo}>
				<p className={css.form__title}>School logotype</p>
				<p className={css.form__subtitle}>
					The logo will be placed in the first page section
				</p>
				<FileUpload accept=".jpg,.png,.svg" handleFiles={handleFiles} blobFile={file} />
			</div>
			<div className={css.form__content}>
				<div className={css.form__section}>
					<p className={css.form__title}>Page content</p>
					<p className={css.form__subtitle}>
						Change or add additional text to the page to better communicate your
						school's policy
					</p>
					<p className={css.form__sectionTitle}>section • 1</p>
					<Controller
						name="titleSection1"
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, onBlur, value } }) => (
							<Textarea
								label="Title"
								name="titleSection1"
								value={value}
								required
								onBlur={onBlur}
								onChange={onChange}
								error={errors?.titleSection1?.message}
							/>
						)}
					/>
					<Controller
						name="subtitleSection1"
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, onBlur, value } }) => (
							<Textarea
								label="Subtitle"
								name="subtitleSection1"
								value={value}
								required
								onBlur={onBlur}
								onChange={onChange}
								error={errors?.subtitleSection1?.message}
							/>
						)}
					/>
				</div>
				<div className={css.form__section}>
					<p className={css.form__sectionTitle}>section • 2</p>
					<Controller
						name="titleSection2"
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, onBlur, value } }) => (
							<Textarea
								label="Title"
								name="titleSection2"
								value={value}
								required
								onBlur={onBlur}
								onChange={onChange}
								error={errors?.titleSection2?.message}
							/>
						)}
					/>
					<Controller
						name="subtitleSection2"
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, onBlur, value } }) => (
							<Textarea
								className={css.form__input}
								label="Subtitle"
								name="subtitleSection2"
								value={value}
								required
								onBlur={onBlur}
								onChange={onChange}
								error={errors?.subtitleSection2?.message}
							/>
						)}
					/>
				</div>
				<div className={css.form__section}>
					<p className={css.form__sectionTitle}>section • 3</p>
					<Controller
						name="titleSection3"
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, onBlur, value } }) => (
							<Textarea
								label="Title"
								className={css.form__input}
								name="titleSection3"
								value={value}
								required
								onBlur={onBlur}
								onChange={onChange}
								error={errors?.titleSection3?.message}
							/>
						)}
					/>
				</div>
				<div className={css.form__section}>
					<p className={css.form__sectionTitle}>section • 4</p>
					<Controller
						name="titleSection4"
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, onBlur, value } }) => (
							<Textarea
								label="Title"
								name="titleSection4"
								value={value}
								required
								onBlur={onBlur}
								onChange={onChange}
								error={errors?.titleSection4?.message}
							/>
						)}
					/>
					<Controller
						name="subtitleSection4"
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, onBlur, value } }) => (
							<Textarea
								className={css.form__input}
								label="Subtitle"
								name="subtitleSection4"
								value={value}
								required
								onBlur={onBlur}
								onChange={onChange}
								error={errors?.subtitleSection4?.message}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	)
}

export default Form
