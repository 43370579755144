import image1 from 'assets/images/prot1.svg'
import image2 from 'assets/images/prot2.svg'
import image3 from 'assets/images/prot3.svg'
import image4 from 'assets/images/prot4.svg'

const protectionData = [
	{
		id: 1,
		text: 'Malicious websites',
		image: image1
	},
	{
		id: 2,
		text: 'DNS tunneling',
		image: image2
	},
	{
		id: 3,
		text: 'Control call back',
		image: image3
	},
	{
		id: 4,
		text: 'Malware & Phishing',
		image: image4
	}
]

export default protectionData
