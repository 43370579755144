import React, { useEffect } from 'react'
import css from './FileUpload.module.scss'
import Preview from './modules/Preview'
import Upload from './modules/Upload'

type FileUploadProps = {
	accept: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleFiles: (files: any) => void
	blobFile: Blob | null
}

type ValidationFile = Blob | Record<string, never>

const validateFile = (file: ValidationFile) => {
	if (file instanceof Blob) {
		if (
			file.type.includes('png') ||
			file.type.includes('svg') ||
			file.type.includes('jpg') ||
			file.type.includes('jpeg')
		) {
			return true
		}
		return false
	}
	return false
}

const prepareFileType = (file: Blob) => {
	if (file.type.split('/')[1].includes('svg')) {
		return new Blob([file], { type: 'image/svg+xml' })
	}
	return file
}

const FileUpload: React.FC<FileUploadProps> = ({ accept, handleFiles, blobFile }) => {
	const [dragActive, setDragActive] = React.useState(false)
	const [file, setFile] = React.useState(blobFile ?? {})
	const [error, setError] = React.useState<string | null>(null)

	useEffect(() => {
		if (blobFile) {
			setFile(blobFile)
		}
	}, [blobFile])

	const validateSize = (image: Blob) => {
		if (image.size < 5_000_000) {
			return true
		}
		setError('Image too big')
		return false
	}

	// handle drag events
	const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true)
		} else if (e.type === 'dragleave') {
			setDragActive(false)
		}
	}

	// triggers when file is dropped
	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setDragActive(false)
		const { files } = e.dataTransfer
		if (files && files[0] && validateSize(files[0])) {
			if (validateFile(files[0])) {
				handleFiles(prepareFileType(files[0]))
				setFile(prepareFileType(files[0]))
				setError(null)
			} else {
				setError('Provide proper image')
			}
		}
	}

	// triggers when file is selected with click
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		const { files } = e.target
		if (files && files[0] && validateSize(files[0])) {
			if (validateFile(files[0])) {
				handleFiles(prepareFileType(files[0]))
				setFile(prepareFileType(files[0]))
				setError(null)
			} else {
				setError('Provide proper image')
			}
		}
	}

	// remove image
	const handleRemove = () => {
		setFile({})
		handleFiles(null)
	}

	return (
		<section id="form-file-upload" onDragEnter={handleDrag} className={css.form_file_upload}>
			{file instanceof Blob ? (
				<Preview file={file} handleRemove={handleRemove} />
			) : (
				<Upload
					handleChange={handleChange}
					handleDrop={handleDrop}
					handleDrag={handleDrag}
					accept={accept}
					dragActive={dragActive}
					error={error}
				/>
			)}
		</section>
	)
}

export default FileUpload
