/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react'
import css from '../FileUpload.module.scss'

export type UploadProps = {
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	handleDrop: (e: React.DragEvent<HTMLDivElement>) => void
	handleDrag: (e: React.DragEvent<HTMLDivElement>) => void
	accept: string
	dragActive: boolean
	error: string | null
}

const Upload: React.FC<UploadProps> = ({
	handleChange,
	accept,
	dragActive,
	handleDrag,
	handleDrop,
	error
}) => {
	const inputRef = React.useRef(null)

	const onButtonClick = () => {
		// @ts-ignore
		inputRef.current.click()
	}
	return (
		<div>
			<input
				ref={inputRef}
				type="file"
				className={css.input_file_upload}
				id="input-file-upload"
				onChange={handleChange}
				accept={accept}
			/>
			<label
				id="label-file-upload"
				htmlFor="input-file-upload"
				className={`${css.label_file_upload} ${dragActive ? css.drag_active : ''}`}
			>
				<div>
					<div className={css.upload_icon}>
						<i className="icon-upload" />
					</div>
					<p className={css.label}>
						<button className={css.upload_button} onClick={onButtonClick} type="button">
							Click to upload
						</button>{' '}
						or drag and drop
					</p>
					{error && <p className={css.error}>{error}</p>}
					<p className={css.define}>Maximum file size 5MB PNG, SVG or JPG</p>
				</div>
			</label>
			{dragActive && (
				<div
					id="drag-file-element"
					className={css.drag_file_element}
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				/>
			)}
		</div>
	)
}

export default Upload
