export interface IFormInputs {
	address: string
	city: string
	country: { label: string; value: string }
	postCode: string
}
type Name = keyof IFormInputs

interface Fields {
	label: string
	name: Name
	placeholder: string
	type?: string
	className: string
	isPassword?: boolean
}

export const fields: Array<Fields> = [
	{
		label: 'City',
		name: 'city',
		placeholder: 'Type city',
		className: 'field__mb24'
	},
	{
		label: 'Address',
		name: 'address',
		placeholder: 'Type address',
		className: 'field__mb24'
	},
	{
		label: 'Postcode',
		name: 'postCode',
		placeholder: 'Type postcode',
		className: 'field__mb24'
	}
]
