import { gql } from '@apollo/client'

export const LOGIN = gql`
	mutation login($code: String!) {
		login(code: $code) {
			ok
			errors {
				fieldName
				errors
			}
			step
		}
	}
`

export const CREATE_PROFILE = gql`
	mutation createProfile(
		$email: String!
		$firstName: String!
		$lastName: String!
		$password1: String!
		$password2: String!
		$schoolPolicy: Boolean!
		$termsOfUse: Boolean!
	) {
		createProfile(
			email: $email
			lastName: $lastName
			firstName: $firstName
			password1: $password1
			password2: $password2
			termsOfUse: $termsOfUse
			schoolPolicy: $schoolPolicy
		) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const UPDATE_PROFILE = gql`
	mutation updateProfile(
		$email: String!
		$firstName: String!
		$lastName: String!
		$password1: String!
		$password2: String!
		$schoolPolicy: Boolean!
		$termsOfUse: Boolean!
	) {
		updateProfile(
			email: $email
			lastName: $lastName
			firstName: $firstName
			password1: $password1
			password2: $password2
			termsOfUse: $termsOfUse
			schoolPolicy: $schoolPolicy
		) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const UPDATE_SITE_INFO = gql`
	mutation updateSiteInfo(
		$logo: Upload
		$subtitleSection1: String
		$subtitleSection2: String
		$subtitleSection4: String
		$titleSection1: String
		$titleSection2: String
		$titleSection3: String
		$titleSection4: String
	) {
		updateSiteInfo(
			logo: $logo
			subtitleSection1: $subtitleSection1
			subtitleSection2: $subtitleSection2
			subtitleSection4: $subtitleSection4
			titleSection1: $titleSection1
			titleSection2: $titleSection2
			titleSection3: $titleSection3
			titleSection4: $titleSection4
		) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const UPDATE_SCHOOL = gql`
	mutation updateSchool($address: String!, $city: String!, $countryId: ID!, $postCode: String!) {
		updateSchool(address: $address, city: $city, countryId: $countryId, postCode: $postCode) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const UPDATE_STEP = gql`
	mutation updateStep($step: Int!) {
		updateStep(step: $step) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_EMAILS = gql`
	mutation createEmails($emails: [String]) {
		createEmails(emails: $emails) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_CHILDREN_QUANTITY = gql`
	mutation createChildrenQty($quantity: Int!) {
		createChildrenQty(quantity: $quantity) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
