/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect } from 'react'

export default function useOnClickOutside(
	ref: React.MutableRefObject<HTMLElement>,
	handler: (event: any) => any
): void {
	const listener = useCallback(
		(event: any) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target)) {
				return
			}
			handler(event)
		},
		[handler, ref]
	)

	useEffect(() => {
		document.addEventListener('mousedown', listener)
		document.addEventListener('touchstart', listener)
		return () => {
			document.removeEventListener('mousedown', listener)
			document.removeEventListener('touchstart', listener)
		}
	}, [ref, handler, listener])
}
