import React from 'react'
import Copyright from 'components/elements/Copyright'
import styles from './Step6Widget.module.scss'

const Step6Widget = ({ children }: { children: React.ReactNode }) => (
	<div className={styles.wrapper}>
		<img src="/images/logotype-big.png" alt="" className={styles.logo} />
		{children}
		<Copyright />
	</div>
)

export default Step6Widget
