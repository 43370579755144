import { useEffect, useState } from 'react'

const useCountdown = (startExtra: boolean) => {
	const remainingTime = 900 // in seconds
	const remainingExtraTime = 300 // in seconds
	const maxPercent = 90

	// initial date
	const [countDownDate] = useState<number>(() =>
		JSON.parse(
			localStorage.getItem('countDownDate') ||
				(new Date().getTime() + remainingTime * 1000).toString()
		)
	)
	// initial extra time date
	const [countDownExtraDate, setCountDownExtraDate] = useState(() =>
		JSON.parse(localStorage.getItem('countDownExtraDate') || new Date().getTime().toString())
	)

	// time left in millisec
	const [countDown, setCountDown] = useState(countDownDate - new Date().getTime())

	// extratime left in millisec
	const [countDownExtra, setCountDownExtra] = useState(1)

	const [percent, setPercent] = useState(0)
	const [extraPercent, setExtraPercent] = useState(0)

	useEffect(() => {
		const local = localStorage.getItem('countDownDate')
		if (!local) {
			localStorage.setItem(
				'countDownDate',
				JSON.stringify(new Date().getTime() + remainingTime * 1000)
			)
		}
	}, [])

	// REGULAR
	// add every 9 sec +1%
	useEffect(() => {
		let interval: ReturnType<typeof setInterval>

		if (!startExtra) {
			interval = setInterval(() => {
				if (countDown > 0) {
					setCountDown(countDownDate - new Date().getTime())
				}
			}, 5000)
		}
		return () => clearInterval(interval)
	}, [countDownDate, countDown, startExtra])

	// percent counter logic
	useEffect(() => {
		const local = localStorage.getItem('countDownExtraDate')
		if (!startExtra) {
			if (countDown <= 0 || local) {
				setPercent(maxPercent)
			} else {
				const seconds = Math.floor(countDown / 1000)
				setPercent(Math.floor(maxPercent - (seconds / remainingTime) * maxPercent))
			}
		}
	}, [countDown, remainingTime, maxPercent, startExtra])

	// SET EXTRA DATE is success
	useEffect(() => {
		const local = localStorage.getItem('countDownExtraDate')
		if (startExtra) {
			const extraCountdownDate = new Date().getTime() + remainingExtraTime * 1000
			setCountDownExtraDate(extraCountdownDate)
			if (!local) {
				localStorage.setItem('countDownExtraDate', JSON.stringify(extraCountdownDate))
			}
		}

		if (local || startExtra) {
			setPercent(maxPercent)
		}
	}, [startExtra])

	// EXTRA TIME
	// add every 3 sec +1%
	useEffect(() => {
		let interval: ReturnType<typeof setInterval>
		const local = localStorage.getItem('countDownExtraDate')
		if (startExtra || local) {
			interval = setInterval(() => {
				if (countDownExtra > 0) {
					setCountDownExtra(countDownExtraDate - new Date().getTime())
				}
			}, 10000)
		}
		return () => clearInterval(interval)
	}, [startExtra, countDownExtra, countDownExtraDate])

	useEffect(() => {
		const local = localStorage.getItem('countDownExtraDate')
		if (local) {
			if (countDownExtra > 0) {
				setCountDownExtra(countDownExtraDate - new Date().getTime())
			}
		}
	}, [countDownExtra, countDownExtraDate])

	// Extra percent counter logic
	useEffect(() => {
		const local = localStorage.getItem('countDownExtraDate')
		if (startExtra || local) {
			if (countDownExtra === 1) {
				return
			}
			if (countDownExtra <= 0) {
				setExtraPercent(10)
			} else {
				const seconds = Math.floor(countDownExtra / 1000)
				setExtraPercent(Math.floor(10 - (seconds / remainingExtraTime) * 10))
			}
		}
	}, [countDownExtra, startExtra])

	return { percent: percent + extraPercent }
}

export default useCountdown
