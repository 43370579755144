import React from 'react'
import Button from 'components/elements/Button'
import styles from './Header.module.scss'

const Header: React.FC = () => (
	<div className={styles.header}>
		<div className="container-preview">
			<div className={styles.header__inner}>
				<img src="/images/logotype-big.png" alt="" className={styles.header__image} />
				<div className={styles.header__right}>
					<div className={styles.menu}>
						<div className={styles.menu__part}>
							<span className={styles.menu__title}>
								Help <i className="icon-chevron-down" />
							</span>
						</div>
						<div className={styles.menu__part}>
							<span className={styles.menu__title}>FAQs</span>
						</div>
					</div>
					<Button variant="outlined" classname={styles.menu__btn}>
						Login
					</Button>
				</div>
			</div>
		</div>
	</div>
)

export default Header
