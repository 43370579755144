import { setupWorker, graphql, SetupWorker } from 'msw'
import { mocks } from './mocks'

declare const window: {
	msw: {
		worker: SetupWorker
		graphql: typeof graphql
	}
} & Window
// This configures a Service Worker with the given request handlers.
// eslint-disable-next-line import/prefer-default-export
export const worker = setupWorker(...mocks)

window.msw = {
	worker,
	graphql
}
