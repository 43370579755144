import React from 'react'
import css from './SchoolField.module.scss'

const SchoolField: React.FC<{ school?: string }> = ({ school }) => (
	<div className={css.field}>
		<i className={`${css.field__icon} icon-school`} />
		<div className={css.field__block}>
			<span className={css.field__school}>School</span>
			<p className={css.field__name} data-testid="school">
				{school ?? 'N\\A'}
			</p>
		</div>
	</div>
)

export default SchoolField
