import classnames from 'classnames'
import useCustomSelect from 'hooks/useCustomSelect'
import Loader from 'components/elements/Loader'
import React, { useEffect } from 'react'
import styles from './CustomSelect.module.scss'
import { ISearchPopupCustomSelect, Value } from './CustomSelect.types'

const CustomSelect: React.FC<ISearchPopupCustomSelect> = (props) => {
	const {
		defaultValue,
		value,
		list,
		label,
		name,
		isDisabled,
		onChange,
		error,
		field,
		toggleClassname,
		labelClassname,
		className,
		withSearch
	} = props

	const {
		isShowDropdown,
		toggleDropdown,
		chooseValue,
		selectedValue,
		ref,
		filteredData,
		filterHandler,
		inputValue = ''
	} = useCustomSelect({
		list,
		defaultValue,
		isDropdown: true,
		isFiltered: true
	})

	const onChooseValue = (val: Value, toggle = true) => {
		if (val) {
			if (onChange) {
				onChange(val)
			}
			chooseValue(val)
		}
		if (toggle) {
			toggleDropdown()
		}
	}
	useEffect(() => {
		if (field) {
			field.onChange(selectedValue)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue])

	const dropClass = classnames(styles.customSelect__drop, {
		[styles.open]: isShowDropdown
	})
	const defaultVal = value || selectedValue || defaultValue

	const selectToggle = classnames(styles.customSelect__toggle, toggleClassname, {
		[styles.customSelect__toggleDisabled]: isDisabled,
		[styles.customSelect__active]: isShowDropdown,
		[styles.customSelect__hasValue]: defaultVal!.value !== 'default',
		[styles.customSelect__error]: error,
		[styles.customSelect__hasIcon]: defaultVal?.icon
	})

	const getClassLi = (el: Value) =>
		classnames(styles.customSelect__btn, {
			[styles.customSelect__btnSelected]:
				el.value === defaultVal?.value ||
				(!selectedValue?.value && el.value === defaultValue?.value)
		})

	const classSelect = classnames(styles.customSelect, className)

	const classLabel = classnames(styles.customSelect__label, labelClassname)

	return (
		<div className={classSelect} ref={ref} data-testid="checkbox-wrapper">
			{label && (
				<label className={classLabel} htmlFor={name} data-testid="checkbox-label">
					{label}
				</label>
			)}
			<button
				type="button"
				id={name}
				onClick={toggleDropdown}
				className={selectToggle}
				disabled={isDisabled}
				data-testid="checkbox-button"
			>
				{defaultVal?.icon && (
					<img
						src={defaultVal?.icon}
						className={styles.customSelect__icon}
						alt=""
						data-testid="checkbox-icon"
					/>
				)}
				{defaultVal?.label}
			</button>
			{!isDisabled && (
				<div className={dropClass} data-testid="checkbox-drop">
					{withSearch && (
						<div
							className={styles.customSelect__searchWrap}
							data-testid="checkbox-search"
						>
							<input
								className={styles.customSelect__search}
								type="text"
								placeholder="Search"
								onChange={filterHandler}
								value={inputValue}
							/>
						</div>
					)}
					<ul className={styles.customSelect__list} data-testid="checkbox-list">
						{filteredData?.map((item) => (
							<li
								key={`${item.value}-${item?.id}`}
								className={styles.customSelect__item}
								data-testid="checkbox-item"
							>
								<button
									type="button"
									className={getClassLi(item)}
									onClick={() => onChooseValue(item)}
									data-testid="checkbox-item-button"
								>
									{item.icon && (
										<img
											src={item.icon}
											className={styles.customSelect__icon}
											alt=""
										/>
									)}
									{item.label}
								</button>
							</li>
						))}
					</ul>
				</div>
			)}
			{error && <p className={styles.error}>{error}</p>}
		</div>
	)
}

const Select = (props: ISearchPopupCustomSelect) => {
	const { isLoading, label, labelClassname, name, className } = props
	const classSelect = classnames(styles.customSelect, className, { [styles.loading]: isLoading })

	const classLabel = classnames(styles.customSelect__label, labelClassname)
	if (isLoading) {
		return (
			<div className={classSelect} data-testid="loading-select">
				{label && (
					<label className={classLabel} htmlFor={name}>
						{label}
					</label>
				)}
				<div className={styles.customSelect__loader}>
					<Loader color="grey" />
				</div>
			</div>
		)
	}

	return <CustomSelect {...props} />
}
export default Select
