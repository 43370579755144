import { InProgress, Success } from 'components/elements/Step6'
import useCountdown from 'hooks/useCountdown'
import { useEffect, useState } from 'react'

type InstanceStatusType = {
	instanceStatus: {
		statusProgress: number
		status: 'SUCCESS' | 'IN_PROGRESS' | 'FAILED' | 'NEW'
	}
}

type Step6Props = {
	instanceStatus: InstanceStatusType['instanceStatus'] | undefined
}

const StatusProgressSuccess = ({ instanceStatus }: Step6Props) => {
	const [start, setStart] = useState(false)
	const { percent } = useCountdown(start)

	useEffect(() => {
		if (instanceStatus?.status === 'SUCCESS') {
			setStart(true)
		}
	}, [instanceStatus?.status])

	const showSuccess = instanceStatus?.status === 'SUCCESS' && percent === 100

	const showInProgresss =
		(instanceStatus?.status === 'SUCCESS' && percent !== 100) ||
		instanceStatus?.status === 'IN_PROGRESS'

	if (!instanceStatus?.status) return null
	return (
		<>
			{showSuccess && <Success />}
			{showInProgresss && <InProgress percent={percent} />}
		</>
	)
}

export default StatusProgressSuccess
