import CircularProgressBar from 'components/elements/CircularProgressBar'
import { useMedia } from 'react-use'
import React from 'react'
import styles from '../Status.module.scss'

const InProgress: React.FC<{ percent: number }> = ({ percent }) => {
	const isSmall = useMedia('(max-width:768px)')

	const circular = {
		strokeWidth: isSmall ? 5 : 8,
		size: isSmall ? 104 : 140
	}
	return (
		<div className={styles.block}>
			<div className={styles.block__progress}>
				<div className={styles.progress}>
					<CircularProgressBar
						strokeWidth={circular.strokeWidth}
						sqSize={circular.size}
						percentage={percent}
					/>
				</div>
			</div>
			<div>
				<h2 className={styles.block__title}>Data activation in progress...</h2>
				<p className={styles.block__subtitle}>
					The activation process of your school's website and profile takes up to 15 min.
					Please wait until the data is activated to get started.
				</p>
			</div>
		</div>
	)
}

export default InProgress
