import { createContext, FC, useContext, useEffect, useMemo, useReducer } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_USER } from 'graphql/query'
import {
	Action,
	State,
	AuthProviderProps,
	AuthStateContextType,
	AuthDispatchContextType
} from './types'

const AuthStateContext = createContext<AuthStateContextType>({} as AuthStateContextType)
const AuthDispatchContext = createContext<AuthDispatchContextType>({} as AuthDispatchContextType)

const initialState: State = {
	isAuthenticated: false,
	isCompleted: false,
	step: 0,
	profile: null,
	domain: null,
	fullDomain: null,
	school: {
		name: undefined,
		country: {
			id: '',
			name: '',
			icon: ''
		},
		city: '',
		address: '',
		postCode: ''
	},
	isLoading: true,
	emails: [],
	siteInfo: null
}

const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case 'LOGIN': {
			window.localStorage.setItem('auth', JSON.stringify(action.payload.domain))
			return {
				...state,
				...action.payload
			}
		}
		case 'LOGOUT': {
			window.localStorage.removeItem('auth')
			return initialState
		}
		case 'SET_DOMAIN':
			return {
				...state,
				domain: action.payload
			}
		case 'SET_LOADING':
			return {
				...state,
				isLoading: action.payload
			}
		case 'UPDATE_SCHOOL': {
			return {
				...state,
				school: {
					...state.school,
					city: action.payload.city,
					address: action.payload.address,
					postCode: action.payload.postCode,
					country: action.payload.country
				}
			}
		}
		case 'UPDATE_PROFILE': {
			return {
				...state,
				profile: {
					...state.profile,
					firstName: action.payload.firstName,
					lastName: action.payload.lastName,
					email: action.payload.email
				}
			}
		}
		case 'UPDATE_SITE_INFO': {
			return {
				...state,
				siteInfo: {
					...state.siteInfo,
					logo: action.payload.logo,
					titleSection1: action.payload.titleSection1,
					subtitleSection1: action.payload.subtitleSection1,
					titleSection2: action.payload.titleSection2,
					subtitleSection2: action.payload.subtitleSection2,
					titleSection3: action.payload.titleSection3,
					titleSection4: action.payload.titleSection4,
					subtitleSection4: action.payload.subtitleSection4
				}
			}
		}
		case 'UPDATE_EMAILS': {
			return {
				...state,
				emails: action.payload.emails
			}
		}
		default:
			throw new Error(`Unknown action: ${action}`)
	}
}

export const AuthContextProvider: FC<AuthProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	const [getUser, { loading }] = useLazyQuery(GET_USER, {
		onError() {
			dispatch({ type: 'LOGOUT' })
		},
		onCompleted({ registration }) {
			if (registration.domain) {
				dispatch({ type: 'LOGIN', payload: { ...registration, isAuthenticated: true } })
			}
		}
	})

	useEffect(() => {
		if (!state.domain) {
			getUser()
		}
	}, [state.domain, getUser])

	useEffect(() => {
		const localAuth = localStorage.getItem('auth')
		if (localAuth && JSON.parse(localAuth)) {
			dispatch({ type: 'SET_DOMAIN', payload: JSON.parse(localAuth) })
		}
	}, [])

	useEffect(() => {
		dispatch({ type: 'SET_LOADING', payload: loading })
	}, [loading])

	const dispatchFn = useMemo(() => ({ dispatch }), [dispatch])
	const getState = useMemo(() => ({ state }), [state])

	return (
		<AuthDispatchContext.Provider value={dispatchFn}>
			<AuthStateContext.Provider value={getState}>{children}</AuthStateContext.Provider>
		</AuthDispatchContext.Provider>
	)
}

export const useStateAuth = () => useContext<AuthStateContextType>(AuthStateContext)
export const useDispatchAuth = () => useContext<AuthDispatchContextType>(AuthDispatchContext)
