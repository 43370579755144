import { useLazyQuery } from '@apollo/client'
import Loader from 'components/elements/Loader'
import { Failed, New, Success } from 'components/elements/Step6'
import { useStateAuth } from 'context/auth'
import { GET_PROGRESS } from 'graphql/query'
import { useEffect } from 'react'
import StatusProgressSuccess from '../StatusProgressSuccess'

type InstanceStatusType = {
	instanceStatus: {
		statusProgress: number
		status: 'SUCCESS' | 'IN_PROGRESS' | 'FAILED' | 'NEW'
	}
}

const InstanceStatus = () => {
	const { state } = useStateAuth()

	const [getProgress, { data, loading, error }] = useLazyQuery<InstanceStatusType>(GET_PROGRESS, {
		fetchPolicy: 'network-only'
	})

	useEffect(() => {
		getProgress()
	}, [getProgress])

	useEffect(() => {
		let timer: ReturnType<typeof setInterval>
		if (['IN_PROGRESS', 'NEW'].includes(data?.instanceStatus.status || '')) {
			timer = setInterval(() => {
				getProgress()
			}, 10000)
		}
		return () => clearInterval(timer)
	}, [getProgress, data])

	if (state.isCompleted) {
		return <Success />
	}
	if (data?.instanceStatus.status === 'NEW') {
		return <New />
	}
	if (data?.instanceStatus.status === 'FAILED' || error) {
		return <Failed />
	}
	if (!data?.instanceStatus && loading) {
		return <Loader center width="104px" color="grey" />
	}
	return <StatusProgressSuccess instanceStatus={data?.instanceStatus} />
}

export default InstanceStatus
