import { useStateAuth } from 'context/auth'
import done from 'assets/images/bg.svg'
import React, { useEffect } from 'react'
import styles from '../Status.module.scss'

const Success: React.FC = () => {
	useEffect(() => {
		const countDownDateLS = localStorage.getItem('countDownDate')
		if (countDownDateLS) {
			const leftTime = JSON.parse(countDownDateLS) - new Date().getTime()
			if (leftTime < -302000) {
				localStorage.removeItem('countDownDate')
				localStorage.removeItem('countDownExtraDate')
			}
		}
	}, [])
	const { state } = useStateAuth()

	return (
		<div className={styles.block}>
			<div className={styles.block__progress}>
				<div className={styles.done}>
					<img src={done} alt="" className={styles.done__bg} />
					<i className={`icon-check ${styles.done__icon}`} />
				</div>
			</div>
			<div className={styles.block__wrapper}>
				<h2 className={styles.block__title}>All done!</h2>
				<p className={styles.block__subtitle}>
					We have sent you an email. Please use the link in the email to get started with
					the school profile.
				</p>
				<a href={state.fullDomain!} className={styles.button}>
					Get started
				</a>
			</div>
		</div>
	)
}

export default Success
