import classNames from 'classnames'
import React, { useEffect } from 'react'
import successIcon from 'assets/images/success.svg'
import infoIcon from 'assets/images/info.svg'
import errorIcon from 'assets/images/error.svg'
import styles from './Badge.module.scss'

const icons = {
	success: successIcon,
	info: infoIcon,
	error: errorIcon
}

export interface BadgeProps {
	show: boolean
	toggleShow: () => void
	color?: 'success' | 'info' | 'error'
	text?: string | string[]
	time?: number
}
const Badge: React.FC<BadgeProps> = ({
	show,
	toggleShow,
	color = 'success',
	text,
	time = 7000
}) => {
	useEffect(() => {
		const timer = setTimeout(() => {
			toggleShow()
		}, time)

		return () => {
			clearTimeout(timer)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show])

	const getClassName = classNames(styles.added, [styles[color]])

	if (!text) {
		return null
	}
	return (
		<div className={getClassName} data-testid="badge">
			<img
				src={icons[color]}
				className={styles.added__icon}
				alt=""
				data-testid="badge-icon"
			/>
			<p className={styles.added__text} data-testid="badge-text">
				{text instanceof Array ? text.map((item) => <span key={item}>{item}</span>) : text}
			</p>
		</div>
	)
}

export default Badge
