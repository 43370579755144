import { graphql } from 'msw'
import successful from './fixtures/successful.json'
import unsuccessful from './fixtures/unsuccessful.json'

const step3 = [
	graphql.mutation('updateSiteInfo', (req, res, ctx) => {
		const { titleSection1 } = req.variables
		if (titleSection1 === 'New text') {
			return res(ctx.data(unsuccessful))
		}
		return res(ctx.data(successful))
	})
]

// eslint-disable-next-line import/prefer-default-export
export { step3 }
