import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './Checkbox.module.scss'
import { TCheckbox } from './Checkbox.types'

const Checkbox: React.FC<TCheckbox> = (props) => {
	const {
		id,
		label,
		value,
		onChange,
		onBlur,
		checked,
		required,
		name,
		disabled,
		error,
		defaultChecked,
		register
	} = props
	const [isChecked, setIsChecked] = useState(checked || false)
	const hasRegister = register ? { ...register(name) } : {}
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked)
		if (onChange) {
			onChange(e)
		}
		if (hasRegister.onChange) {
			hasRegister.onChange(e)
		}
	}
	const classLabel = classNames(styles.checkbox__label, {
		[styles.disabled]: disabled,
		[styles.error]: error,
		[styles.checked]: isChecked
	})
	const classCheckbox = classNames(styles.checkbox, {
		[styles.disabled]: disabled
	})
	const inputProps = {
		type: 'checkbox',
		id: `${name}_${id}`,
		className: styles.checkbox__input,
		value,
		onBlur,
		checked: isChecked,
		name,
		disabled,
		required,
		defaultChecked,
		...hasRegister,
		onChange: handleChange
	}
	return (
		<div className={classCheckbox} data-testid="checkbox">
			<input {...inputProps} data-testid="checkbox-input" type="checkbox" />
			<label htmlFor={`${name}_${id}`} className={classLabel} data-testid="checkbox-label">
				{label}
			</label>
			{error && <span className="formControl__error">{error}</span>}
		</div>
	)
}

export default Checkbox
