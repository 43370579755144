import React from 'react'
import './CircularProgressBar.css'

type CircularProgressBarProps = {
	sqSize?: number
	strokeWidth?: number
	percentage: number
	transitionDuration?: number
	transitionTimingFunction?: string
	ballStrokeWidth?: number
	hideBall?: boolean
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
	sqSize = 132,
	strokeWidth = 8,
	percentage,
	transitionDuration = 0.5,
	transitionTimingFunction = 'ease',
	ballStrokeWidth = 16,
	hideBall = false
}) => {
	// SVG centers the stroke width on the radius, subtract out so circle fits in square
	const radius = (sqSize - strokeWidth) / 2
	const size = sqSize + 10
	// Enclose cicle in a circumscribing square
	const viewBox = `-5 -5 ${size} ${size}`
	// Arc length at 100% coverage is the circle circumference
	const dashArray = radius * Math.PI * 2
	// Scale 100% coverage overlay with the actual percent
	const dashOffset = dashArray - (dashArray * percentage) / 100

	const center = sqSize / 2

	const offset = (dashArray * (100 - percentage * 1)) / 100
	return (
		<div className="circle-wrapper" style={{ width: size, height: size }}>
			<svg width={size} height={size} viewBox={viewBox}>
				<circle
					className="circle-background"
					cx={center}
					cy={center}
					r={radius}
					strokeWidth={`${strokeWidth}px`}
				/>
				<circle
					className="circle-progress"
					cx={center}
					cy={center}
					r={radius}
					strokeWidth={`${strokeWidth}px`}
					// Start progress marker at 12 O'Clock
					transform={`rotate(-90 ${center} ${center})`}
					style={{
						strokeDasharray: dashArray,
						strokeDashoffset: dashOffset,
						transition: `stroke-dashoffset ${transitionDuration}s ${transitionTimingFunction}`
					}}
				/>
				{!hideBall && (
					<circle
						style={{
							transition: `stroke-dashoffset ${transitionDuration}s ${transitionTimingFunction}`
						}}
						transform={`rotate(-90 ${center} ${center})`}
						id="path"
						cx={center}
						cy={center}
						r={radius}
						strokeWidth={ballStrokeWidth}
						strokeDasharray={`1 ${dashArray}`}
						strokeDashoffset={offset}
						fill="none"
						stroke="#519bde"
						strokeLinecap="round"
					/>
				)}
			</svg>
			<span className="circle-text">
				{percentage}
				<sup className="circle-percent">%</sup>
			</span>
		</div>
	)
}

export default CircularProgressBar
