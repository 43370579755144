import React from 'react'
import { useWatch, useFormContext } from 'react-hook-form'
import { useStateAuth } from 'context/auth'
import styles from './HeroBlock.module.scss'

type HeroBlockProps = {
	logo: Blob | null
}

const HeroBlock: React.FC<HeroBlockProps> = ({ logo }) => {
	const { control } = useFormContext()
	const { state } = useStateAuth()

	const title = useWatch({
		control,
		name: 'titleSection1'
	})

	const subtitle = useWatch({
		control,
		name: 'subtitleSection1'
	})

	return (
		<div className={styles.hero}>
			<div className={styles.hero__container}>
				<h2 color="white" className={styles.hero__title}>
					{title}
				</h2>
				<p className={styles.hero__subtitle}>{subtitle}</p>
			</div>
			<div className={styles.hero__school}>
				{logo && <img src={URL.createObjectURL(logo)} alt="School Logo" />}
				{state.school?.name && (
					<p className={styles.hero__schoolName}>{state.school.name}</p>
				)}
			</div>
		</div>
	)
}

export default HeroBlock
