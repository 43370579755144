import Button from 'components/elements/Button'
import Header from 'components/modules/Header'
import React, { useState } from 'react'
import FieldEmails from 'components/elements/FieldEmails'
import { useMutation } from '@apollo/client'
import { CREATE_EMAILS, UPDATE_STEP } from 'graphql/mutation'
import { useNavigate } from 'react-router-dom'
import useEmails from 'components/elements/FieldEmails/useEmails'
import WithAuth from 'hoc/withAuth'
import { useDispatchAuth } from 'context/auth'
import { serializeErrors } from 'utils'
import Badge from 'components/elements/Badge'

const Step4: React.FC = () => {
	const { value, items, resetEmails, handleError, ...otherValues } = useEmails()

	const navigate = useNavigate()
	const { dispatch } = useDispatchAuth()
	const [errors, setErrors] = useState<{ [key: string]: { message: string[] } }>({})
	const [show, setShow] = useState<boolean>(false)
	const [updateStep] = useMutation(UPDATE_STEP)
	const [sendInvites, { loading }] = useMutation(CREATE_EMAILS, {
		onCompleted({ createEmails }, options) {
			if (!createEmails.ok) {
				setErrors(serializeErrors(createEmails.errors))
				setShow(true)
				handleError(
					createEmails.errors
						.filter(
							(item: { fieldName: string }) => item.fieldName !== 'nonFieldErrors'
						)
						.map((item: { errors: string[] }) => item.errors)
						.join(', ')
				)
			} else {
				setErrors({})
				setShow(false)
				dispatch({
					type: 'UPDATE_EMAILS',
					payload: {
						emails: options!.variables!.emails as string[]
					}
				})
				updateStep({
					variables: {
						step: 5
					}
				})
				navigate(`/step5`)
				handleError('')
				resetEmails()
			}
		}
	})

	const onCancel = () => {
		navigate(`/step3`)
	}

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		const getEmails = (): string[] => {
			if (!items.length && value) {
				return [value]
			}
			if (items.length && value) {
				return [...items, value]
			}
			return items
		}
		if (!items.length && !value) {
			updateStep({
				variables: {
					step: 5
				}
			})
			navigate(`/step5`)
		} else {
			sendInvites({
				variables: {
					emails: getEmails()
				}
			})
		}
	}

	return (
		<div className="step">
			<Header step={4} />

			<form className="step__form container-lg" onSubmit={onSubmit}>
				{show && (
					<Badge
						color="error"
						text={errors?.nonFieldErrors?.message}
						show={show}
						toggleShow={() => setShow(false)}
					/>
				)}
				<div className="">
					<div className="step__titles container-xs">
						<h2 className="step__title">Invite colleagues (optional)</h2>
						<p className="step__subtitle">
							The school profile can be available to more than one user. Add
							colleagues if it necessary.
						</p>
					</div>
					<div className="container container-md">
						<FieldEmails
							name="emails"
							label="Email"
							items={items}
							value={value}
							placeholder="Type email, comma separated"
							{...otherValues}
						/>
					</div>
				</div>
				<div className="step-buttons">
					<div className="step-buttons__wrapper">
						<Button
							variant="empty"
							classname="step-buttons__backBtn"
							onClick={onCancel}
						>
							<i className="icon-arrow-left" />
							Back
						</Button>
						<Button
							type="submit"
							variant="primary"
							classname="step-buttons__next"
							isLoading={loading}
						>
							Continue
						</Button>
					</div>
				</div>
			</form>
		</div>
	)
}

export default WithAuth(Step4)
