import Button from 'components/elements/Button'
import Header from 'components/modules/Header'
import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatchAuth, useStateAuth } from 'context/auth'
import { useNavigate } from 'react-router-dom'
import { UPDATE_SITE_INFO, UPDATE_STEP } from 'graphql/mutation/index'
import { useMutation } from '@apollo/client'
import WithAuth from 'hoc/withAuth'
import { SiteInfoType } from 'context/auth/types'
import { serializeErrors } from 'utils'
import Badge from 'components/elements/Badge'
import css from './Step3.module.scss'
import Form from './modules/Form/Form'
import Preview from './modules/Preview/Preview'

export type TFormOutput = {
	logo: Blob
	subtitleSection1: string
	subtitleSection2: string
	subtitleSection4: string
	titleSection1: string
	titleSection2: string
	titleSection3: string
	titleSection4: string
}

const Step3 = () => {
	const { state } = useStateAuth()

	const methods = useForm<TFormOutput>()
	const [logo, setLogo] = useState<Blob | null>(null)
	const navigate = useNavigate()
	const [errors, setErrors] = useState<{ [key: string]: { message: string[] } }>({})
	const [show, setShow] = useState<boolean>(false)
	const { dispatch } = useDispatchAuth()
	const [updateStep] = useMutation(UPDATE_STEP)
	const [upload, { loading }] = useMutation(UPDATE_SITE_INFO, {
		onCompleted({ updateSiteInfo }, options) {
			if (!updateSiteInfo.ok) {
				setErrors(serializeErrors(updateSiteInfo.errors))
				setShow(true)
			}
			if (updateSiteInfo.ok) {
				setErrors({})
				setShow(false)
				dispatch({
					type: 'UPDATE_SITE_INFO',
					payload: options!.variables! as SiteInfoType
				})
				navigate('/step4')
				updateStep({
					variables: {
						step: 4
					}
				})
			}
		}
	})

	const onSubmit: SubmitHandler<TFormOutput> = async (data: TFormOutput) => {
		upload({
			variables: {
				...data,
				logo
			}
		})
	}

	useEffect(() => {
		if (state.siteInfo) {
			methods.setValue('titleSection1', state.siteInfo?.titleSection1 ?? '')
			methods.setValue('subtitleSection1', state.siteInfo?.subtitleSection1 ?? '')
			methods.setValue('titleSection2', state.siteInfo?.titleSection2 ?? '')
			methods.setValue('subtitleSection2', state.siteInfo?.subtitleSection2 ?? '')
			methods.setValue('titleSection3', state.siteInfo?.titleSection3 ?? '')
			methods.setValue('titleSection4', state.siteInfo?.titleSection4 ?? '')
			methods.setValue('subtitleSection4', state.siteInfo?.subtitleSection4 ?? '')
		}
	}, [state.siteInfo, methods])

	const uploadFiles = (file: Blob) => setLogo(file)

	const onCancel = () => {
		navigate('/step2')
	}

	useEffect(() => {
		if (state.siteInfo?.logo) {
			if (state.siteInfo.logo instanceof Blob) {
				setLogo(state.siteInfo.logo)
			} else {
				fetch(new URL(String(state.siteInfo.logo)))
					.then((res) => res.blob())
					.then((blob) => {
						setLogo(blob)
					})
			}
		}
	}, [state.siteInfo?.logo])

	return (
		<div className="step">
			<Header step={3} />
			<FormProvider {...methods}>
				<form className="step__form" onSubmit={methods.handleSubmit(onSubmit)}>
					{show && (
						<Badge
							color="error"
							text={errors?.nonFieldErrors?.message}
							show={show}
							toggleShow={() => setShow(false)}
						/>
					)}
					<div className="container centered-container-md">
						<div className="step__titles">
							<h2 className="step__title">Customise website (optional)</h2>
							<p className="step__subtitle">
								Add the school logo and change the content of the page if it
								necessary. You can always edit this later.
							</p>
						</div>
						<div className={css.wrapper}>
							<Form handleFiles={uploadFiles} file={logo} />
							<Preview logo={logo} />
						</div>
					</div>
					<div className="step-buttons">
						<div className="step-buttons__wrapper">
							<Button
								variant="empty"
								classname="step-buttons__backBtn"
								onClick={onCancel}
							>
								<i className="icon-arrow-left" />
								Back
							</Button>
							<Button
								type="submit"
								variant="primary"
								classname="step-buttons__next"
								isLoading={loading}
							>
								Continue
							</Button>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default WithAuth(Step3)
