import React from 'react'
import Button from 'components/elements/Button'
import { useMedia } from 'react-use'
import css from '../FileUpload.module.scss'

export type PreviewProps = {
	file: Blob
	handleRemove: () => void
}

const Preview: React.FC<PreviewProps> = ({ file, handleRemove }) => {
	const isWide = useMedia('(min-width: 768px)')

	return (
		<div className={css.preview}>
			<div className={css.preview__picture}>
				<img
					src={URL.createObjectURL(file)}
					alt={`file preview `}
					className={css.preview__image}
				/>
			</div>
			<Button variant="outlined" classname={css.preview__remove} onClick={handleRemove}>
				Remove{' '}
				{isWide && (
					<>
						logo <i className={`${css.preview__icon} icon-big-delete`} />{' '}
					</>
				)}
			</Button>
		</div>
	)
}

export default Preview
