import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './styles/index.scss'
import './index.css'
import { ApolloProvider } from '@apollo/client'
import { AuthContextProvider } from 'context/auth'
import App from './App'
import { createApolloClient } from './apollo/client'
import { worker } from './mocks/browser'

const client = createApolloClient()
const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = createRoot(rootElement)

async function bootstrapApp() {
	if (process.env.REACT_APP_TEST_MODE) {
		await worker.start()
	}
	root.render(
		<BrowserRouter>
			<ApolloProvider client={client}>
				<AuthContextProvider>
					<App />
				</AuthContextProvider>
			</ApolloProvider>
		</BrowserRouter>
	)
}
bootstrapApp()
