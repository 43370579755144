import failed from 'assets/images/failed-bg.svg'
import React from 'react'
import styles from '../Status.module.scss'

const InProgress: React.FC = () => (
	<div className={styles.block}>
		<div className={styles.block__progress}>
			<div className={styles.done}>
				<img src={failed} alt="" className={styles.done__bg} />
				<i className={`icon-close ${styles.done__failed}`} />
			</div>
		</div>
		<div>
			<h2 className={styles.block__title}>Something went wrong</h2>
			<p className={styles.block__subtitle}>
				During the activation process, something may have gone wrong. Please try again after
				some time.
			</p>
		</div>
	</div>
)

export default InProgress
