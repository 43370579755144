import { graphql } from 'msw'
import successful from './fixtures/successful.json'
import countries from './fixtures/countries.json'
import unsuccessful from './fixtures/unsuccessful.json'

const step2 = [
	graphql.query('countries', (req, res, ctx) => res(ctx.data(countries))),

	graphql.mutation('updateSchool', (req, res, ctx) => {
		const { postcode, address, city } = req.variables
		if (postcode === '---' || address === '---' || city === '---') {
			return res(ctx.data(unsuccessful))
		}
		return res(ctx.data(successful))
	})
]

// eslint-disable-next-line import/prefer-default-export
export { step2 }
