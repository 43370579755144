import PageLoading from 'components/elements/PageLoading'
import { useStateAuth } from 'context/auth'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const WithAuth = (Component: React.FunctionComponent) =>
	// eslint-disable-next-line react/function-component-definition
	function Comp() {
		const navigate = useNavigate()
		const { state } = useStateAuth()
		useEffect(() => {
			const shouldRedirect = !state.domain && !state.isAuthenticated && !state.isLoading
			if (shouldRedirect) {
				navigate('/')
			}
			if (state.isCompleted) {
				navigate('/step6')
			}
		}, [state, navigate])

		if (state.isLoading) {
			return <PageLoading />
		}
		return <Component />
	}

export default WithAuth
