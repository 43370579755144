import failed from 'assets/images/failed-bg.svg'
import styles from '../Status.module.scss'

const New = () => (
	<div className={styles.block}>
		<div className={styles.block__progress}>
			<div className={styles.done}>
				<img src={failed} alt="" className={styles.done__bg} />
				<i className={`icon-clock ${styles.done__failed}`} />
			</div>
		</div>
		<div className={styles.block__wrapper}>
			<h2 className={styles.block__title}>Deployment in queue...</h2>
			<p className={styles.block__subtitle}>
				Your instance still in progress and in the queue. Please wait, you deployment will
				start soon.
			</p>
		</div>
	</div>
)

export default New
