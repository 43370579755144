export const isNullOrUndefined = (value: string | undefined) =>
	value === null || value === undefined

export const transformCountries = (data: Array<{ name: string; id: string; icon: string }>) =>
	data?.map((item: { name: string; id: string; icon: string }) => ({
		label: item.name,
		value: item.id,
		icon: item.icon
	}))

export const serializeErrors = (
	errors: Array<{
		errors: string[]
		fieldName: string
	}>
) =>
	errors
		.map((err) => ({
			[err.fieldName]: {
				message: err.errors
			}
		}))
		.reduce((acc, cur) => ({ ...acc, ...cur }), {})
