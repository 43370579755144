import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { InputProps } from './types'

const Textarea: React.FC<InputProps> = ({
	label,
	name,
	inputNativeProps,
	error,
	className,
	required,
	value,
	inputClassName,
	...otherProps
}) => {
	const ref = useRef<HTMLTextAreaElement>(null)

	useEffect(() => {
		const textAreaRef = ref.current
		if (textAreaRef) {
			// We need to reset the height momentarily to get the correct scrollHeight for the textarea
			textAreaRef.style.height = '0px'
			const { scrollHeight } = textAreaRef

			// We then set the height directly, outside of the render loop
			// Trying to set this with state or a ref will product an incorrect value.
			textAreaRef.style.height = `${scrollHeight}px`
			if (textAreaRef.scrollHeight > 116) {
				textAreaRef.style.overflow = 'auto'
			} else {
				textAreaRef.style.overflow = 'hidden'
			}
		}
	}, [value])

	const classInput = classNames('formControl__input', 'formControl__textarea', inputClassName, {
		formControl__errorBorder: error
	})
	const innerProps = {
		...otherProps,
		...inputNativeProps
	}
	return (
		<div className={classNames('formControl', className)}>
			{label && (
				<label htmlFor={name} className="formControl__label" data-testid="textarea-label">
					{label}
				</label>
			)}
			<div className="formControl__wrap">
				<textarea
					rows={1}
					data-testid="textarea"
					{...innerProps}
					required={required}
					ref={ref}
					className={classInput}
					id={name}
					name={name}
					value={value}
				/>
			</div>
			{error && (
				<span className="formControl__error" data-testid="textarea-error">
					{error}
				</span>
			)}
		</div>
	)
}

export default Textarea
