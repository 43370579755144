import { useRef, useState } from 'react'
import useOnClickOutside from './useOnClickOutside'

type Value = { id?: string; label: string; value: string; icon?: string }

type TUseCustomSelect = {
	list?: Array<Value>
	defaultValue?: Value
	value?: Value
	isDropdown?: boolean
	isFiltered?: boolean
}

type OutTUseCustomSelect = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ref: any
	toggleDropdown: () => void
	filteredData?: Array<Value>
	selectedValue: Value | null
	inputValue?: string
	isShowDropdown: boolean
	chooseValue: (val: Value) => void
	filterHandler?: (event: { target: { value: string } }) => void
}

const useCustomSelect = (props: TUseCustomSelect): OutTUseCustomSelect => {
	const { list = [], defaultValue, isDropdown = false } = props
	const ref = useRef(null)
	const checkDefaultValue = () => {
		if (defaultValue) {
			return defaultValue
		}
		if (list.length) {
			return list[0]
		}
		return null
	}

	const [selectedValue, setValue] = useState(checkDefaultValue())

	const [data, setData] = useState(list)
	const [inputValue, setInputValue] = useState<string>('')
	const [isShowDropdown, setShow] = useState<boolean>(false)

	const filterHandler = (event: { target: { value: string } }): void => {
		const { value: nativeValue } = event.target
		if (list.length) {
			const filtered = list.filter((el) =>
				el?.label.toString().toLowerCase().includes(nativeValue?.toString().toLowerCase())
			)
			setInputValue(nativeValue)
			setData(filtered)
		}
	}
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	useOnClickOutside(ref, () => setShow(false))

	function chooseValue(val: Value) {
		setValue(val)
		if (isDropdown) {
			setShow(false)
		}
	}

	function toggleDropdown() {
		setShow(!isShowDropdown)
	}

	return {
		selectedValue,
		toggleDropdown,
		chooseValue,
		isShowDropdown,
		ref,
		filteredData: inputValue ? data : list,
		filterHandler,
		inputValue
	}
}

export default useCustomSelect
