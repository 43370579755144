import { Link } from 'react-router-dom'
import React from 'react'

import styles from './Logo.module.scss'

const Logo: React.FC<{ link?: string }> = ({ link }) => {
	if (link) {
		return (
			<Link to="/" className={styles.link}>
				<img src="/images/logotype-big.png" alt="" className={styles.image} />
			</Link>
		)
	}
	return <img src="/images/logotype-big.png" alt="" className={styles.image} />
}
export default Logo
