import links, { termsAndPolicyLinks } from 'utils/links'
import React from 'react'
import styles from './Footer.module.scss'

type FooterLinksProps = {
	title: string
	hrefs: Array<{ title: string; url: string }>
}
const FooterLinks: React.FC<FooterLinksProps> = ({ title, hrefs }) => (
	<div className={styles.desktop__part}>
		<div className={styles.desktop__title}>{title}</div>
		<ul className={styles.desktop__list}>
			{hrefs.map((link) => (
				<li key={link.title} className={styles.desktop__link}>
					{link.title}
				</li>
			))}
		</ul>
	</div>
)
const Footer = () => (
	<div className={styles.desktop}>
		<div className="container-preview">
			<div className={styles.desktop__top}>
				<div className={styles.desktop__logo}>
					<img src="/images/logotype.png" alt="" className={styles.desktop__image} />
				</div>
				<div className={styles.desktop__links}>
					<FooterLinks title="About us" hrefs={links.aboutUs} />
					<FooterLinks title="Learn" hrefs={links.learn} />
				</div>
			</div>
			<div className={styles.desktop__footer}>
				<p className={styles.desktop__copyright}>
					© 2022 School Mobile. All rights reserved.
				</p>
				<div className={styles.desktop__policy}>
					<div className={styles.desktop__part}>
						<span className={styles.desktop__link}>
							{termsAndPolicyLinks.policy.title}
						</span>
					</div>
					<div className={styles.desktop__part}>
						<span className={styles.desktop__link}>
							{termsAndPolicyLinks.terms.title}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default Footer
