import { useFormContext, useWatch } from 'react-hook-form'
import styles from './PolicyBlock.module.scss'

const PolicyBlock = () => {
	const { control } = useFormContext()
	const title = useWatch({
		control,
		name: 'titleSection4'
	})
	const subtitle = useWatch({
		control,
		name: 'subtitleSection4'
	})

	return (
		<div className={styles.block}>
			<div className="container-preview">
				<h2>{title}</h2>
				<p className={styles.block__subtitle}>{subtitle}</p>
				<div className={styles.link}>
					Read more <i className={`${styles.block__arrow} icon-big-arrow-right`} />
				</div>
			</div>
		</div>
	)
}

export default PolicyBlock
