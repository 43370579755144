import { useStateAuth } from 'context/auth'
import css from './PreviewHeader.module.scss'

const PreviewHeader = () => {
	const { state } = useStateAuth()

	return (
		<div className={css.preview__header}>
			<div className={css.preview__icons}>
				<span className={`${css.preview__icon} ${css.red}`} />
				<span className={`${css.preview__icon} ${css.orange}`} />
				<span className={`${css.preview__icon} ${css.green}`} />
			</div>
			<div className={css.preview__domain}>{state.domain}</div>
		</div>
	)
}

export default PreviewHeader
