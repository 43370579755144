import classNames from 'classnames'
import React from 'react'
import { useToggle } from 'react-use'
import { InputProps } from './types'

const FieldInput: React.FC<InputProps> = (props) => {
	const {
		label,
		type = 'text',
		name,
		inputNativeProps,
		error,
		className,
		required,
		isPassword,
		inputClassName,
		onBlur,
		...otherProps
	} = props
	const [show, setShow] = useToggle(false)

	const classInput = classNames('formControl__input', inputClassName, {
		formControl__errorBorder: error
	})
	const passwordIcon = !show ? (
		<span className="icon-big-eye-off" />
	) : (
		<span className="icon-big-eye-on" />
	)
	const getPasswordType = !show ? 'password' : 'text'

	const innerProps = {
		type: isPassword ? getPasswordType : type,
		className: classInput,
		id: name,
		name,
		...otherProps
	}
	return (
		<div className={classNames('formControl', className)}>
			{label && (
				<label htmlFor={name} className="formControl__label" data-testid="label">
					{label}
				</label>
			)}
			<div className="formControl__wrap">
				<input
					{...innerProps}
					{...inputNativeProps}
					onBlur={onBlur}
					required={required}
					data-testid="input"
				/>
				{isPassword && (
					<button type="button" className="formControl__btn-show" onClick={setShow}>
						{passwordIcon}
					</button>
				)}
			</div>
			{error && (
				<span className="formControl__error" data-testid="error">
					{error}
				</span>
			)}
		</div>
	)
}

export default FieldInput
