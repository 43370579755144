import classNames from 'classnames'
import React from 'react'
import { InputProps } from './types'
import css from './FieldEmails.module.scss'

const FieldEmails: React.FC<InputProps> = (props) => {
	const {
		label,
		name,
		className,
		placeholder,
		value,
		items,
		handleKeyDown,
		handleChange,
		handlePaste,
		handleDelete,
		error
	} = props

	const classInput = classNames(css.form__block, {
		formControl__errorBorder: error
	})

	const placehold = items.length ? '' : placeholder

	return (
		<div className={classNames('formControl', className)} data-testid="emails-wrapper">
			<label htmlFor={name}>
				{label && (
					<span className="formControl__label" data-testid="emails-label">
						{label}
					</span>
				)}
				<div className={classInput} data-testid="emails-input-wrapper">
					{items.map((item) => (
						<div key={item} className={css.form__chip} data-testid="emails-item">
							{item}
							<button
								type="button"
								data-testid="emails-button"
								className={css.form__remove}
								onClick={() => handleDelete(item)}
							>
								<i className="icon-close" />
							</button>
						</div>
					))}
					<input
						className={css.form__input}
						value={value}
						name={name}
						id={name}
						data-testid="emails-input"
						placeholder={placehold}
						onKeyDown={handleKeyDown}
						onChange={handleChange}
						onPaste={handlePaste}
						autoComplete="off"
					/>
				</div>
			</label>

			{error && (
				<span className="formControl__error" data-testid="emails-error">
					{error}
				</span>
			)}
		</div>
	)
}

export default FieldEmails
