import InstanceStatus from 'components/modules/InstanceStatus'
import Step6Widget from 'components/widgets/Step6Widget'
import WithAuth from 'hoc/withAuth'

const Step6 = () => (
	<Step6Widget>
		<InstanceStatus />
	</Step6Widget>
)

export default WithAuth(Step6)
