import { graphql } from 'msw'
import successful from './fixtures/successful.json'
import unsuccessful from './fixtures/unsuccessful.json'

const step1 = [
	graphql.mutation('updateProfile', (req, res, ctx) => {
		const { password1, password2 } = req.variables

		if (password1 !== password2) {
			return res(ctx.delay(1000), ctx.data(unsuccessful))
		}
		return res(ctx.delay(1000), ctx.data(successful))
	})
]

// eslint-disable-next-line import/prefer-default-export
export { step1 }
