import React from 'react'
import Logo from 'components/elements/Logo'
import Progress from 'components/elements/Progress'
import styles from './Header.module.scss'

export type HeaderProps = {
	step: 1 | 2 | 3 | 4 | 5
}

const Header: React.FC<HeaderProps> = ({ step }) => (
	<div className={styles.header}>
		<div className="container">
			<div className={styles.header__inner}>
				<Logo />
				<Progress step={step} />
			</div>
		</div>
	</div>
)

export default Header
