import { useMutation, useQuery } from '@apollo/client'
import Badge from 'components/elements/Badge'
import Button from 'components/elements/Button'
import Select from 'components/elements/CustomSelect'
import FieldInput from 'components/elements/FieldInput'
import SchoolField from 'components/elements/SchoolField'
import Header from 'components/modules/Header'
import { UPDATE_SCHOOL, UPDATE_STEP } from 'graphql/mutation'
import { useEffect, useState } from 'react'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { GET_COUNTRIES } from 'graphql/query'
import { serializeErrors, transformCountries } from 'utils'
import { useNavigate } from 'react-router-dom'
import { useDispatchAuth, useStateAuth } from 'context/auth'
import WithAuth from 'hoc/withAuth'
import { IFormInputs, fields } from './fields'

const Step2 = () => {
	const { data: getCountries, loading: loadingCountries } = useQuery(GET_COUNTRIES)
	const countries = transformCountries(getCountries?.countries)
	const [errors, setErrors] = useState<{ [key: string]: { message: string[] } }>({})
	const [show, setShow] = useState<boolean>(false)
	const navigate = useNavigate()
	const { state } = useStateAuth()
	const { dispatch } = useDispatchAuth()
	const [updateStep] = useMutation(UPDATE_STEP)
	const methods = useForm({
		mode: 'onSubmit',
		defaultValues: {
			address: '',
			city: '',
			country: countries
				? countries[0]
				: {
						label: 'United Kingdom',
						value: '1',
						icon: 'https://schoolsmobile.ams3.digitaloceanspaces.com/onboarding/media/united_kingdom.png'
				  },
			postCode: ''
		}
	})

	useEffect(() => {
		if (state.school) {
			methods.setValue('address', state.school.address ?? '')
			methods.setValue('city', state.school.city ?? '')
			methods.setValue('postCode', state.school.postCode ?? '')
			if (state.school.country) {
				methods.setValue('country', {
					label: state.school.country.name,
					value: state.school.country.id,
					icon: state.school.country.icon
				})
			}
		}
	}, [state.school, methods])

	const [update, { loading }] = useMutation(UPDATE_SCHOOL, {
		onCompleted({ updateSchool }, options) {
			if (!updateSchool.ok) {
				setErrors(serializeErrors(updateSchool.errors))
				setShow(true)
			} else {
				updateStep({
					variables: {
						step: 3
					}
				})
				dispatch({
					type: 'UPDATE_SCHOOL',
					payload: {
						city: options!.variables!.city as string,
						postCode: options!.variables!.postCode as string,
						address: options!.variables!.address as string,
						country: {
							name: options!.variables!.country.label,
							id: options!.variables!.country.value,
							icon: options!.variables!.country.icon
						}
					}
				})
				setErrors({})
				navigate(`/step3`)
				setShow(false)
			}
		}
	})
	const { handleSubmit, control } = methods

	const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
		update({ variables: { ...data, countryId: data.country.value } })
	}

	const onCancel = () => {
		navigate('/step1')
	}

	return (
		<div className="step">
			<Header step={2} />
			<FormProvider {...methods}>
				<form className="step__form container-lg" onSubmit={handleSubmit(onSubmit)}>
					{show && (
						<Badge
							color="error"
							text={errors?.nonFieldErrors?.message}
							show={show}
							toggleShow={() => setShow(false)}
						/>
					)}
					<div className="container container-md  ">
						<div className="step__titles">
							<h2 className="step__title">Add school information</h2>
						</div>
						<div>
							<SchoolField school={state.school?.name} />
							<Controller
								name="country"
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<Select
										isLoading={loadingCountries}
										value={field.value}
										onChange={field.onChange}
										name="country"
										label="Country"
										list={countries}
										error={errors?.country?.message?.join('. ')}
									/>
								)}
							/>
							{fields.map((item) => (
								<Controller
									key={item.name}
									name={item.name}
									control={control}
									rules={{ required: true }}
									render={({ field: { onChange, onBlur, value } }) => (
										<FieldInput
											value={value as string}
											required
											{...item}
											onBlur={onBlur}
											onChange={onChange}
											error={errors[item.name]?.message?.join('. ')}
										/>
									)}
								/>
							))}
						</div>
					</div>
					<div className="step-buttons">
						<div className="step-buttons__wrapper">
							<Button
								variant="empty"
								classname="step-buttons__backBtn"
								onClick={onCancel}
							>
								<i className="icon-arrow-left" />
								Back
							</Button>
							<Button
								type="submit"
								variant="primary"
								classname="step-buttons__next"
								isLoading={loading}
							>
								Continue
							</Button>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default WithAuth(Step2)
